import { useContext } from "react"
import { NavLink, useNavigate } from "react-router-dom"
import { ThemeContext } from "../../Context/Theme/Context"
import DefaultImage from "../../images/default.jpg"
import moment from "moment"

const InsightsItem = ({
    slice
}: any) => {
    const navigate = useNavigate()
    const { blogList } = useContext(ThemeContext)
    return (
        <div className="blog-list">
            <div className="container">
                <div className="row">
                    {blogList?.slice(0, slice)?.map((item:any) => {
                        return (
                            <div className="col-md-4 col-12 mb-4" onClick={() => navigate(`/insights/${item?.slug}`)}>
                                <div className="imagePlaeholder">
                                    <img src={item?.image ? item?.image: DefaultImage} alt="" title="" />
                                </div>
                                <div className="inner">
                                    <div className="tag">
                                        <div className="dateTag">{moment(item?.publish_date).format("DD/MM/YYYY")}</div>
                                    </div>
                                    <h3>{item?.title}</h3>
                                    <div className="short_description" dangerouslySetInnerHTML={{ __html: item?.short_description }} />
                                    <p><NavLink to={`/insights/${item?.slug}`}>Read More</NavLink></p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default InsightsItem