import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../Context/Theme/Context";
import ContactForm from "../components/ContactForm";
import BannerContact from "../images/contactus.jpg"
interface HomeProps {
    link: string;
}

type formDataProps = {
    email_id: string;
    full_name: string;
    business_name?: string;
    phone_number?: string;
    commnet?: string;
};


const ContactUs = ({ link }: HomeProps) => {
    const { orgUser } = useContext(ThemeContext)
    const [disabled, setDisabled] = useState(true);
    const [isFormSubmitted, setIsFormSubmitted]: any = useState(false);
    const [formData, setFormData] = useState<formDataProps>({
        full_name: '',
        business_name: '',
        email_id: '',
        phone_number: '',
        commnet: ""
    });
    const onChangeSingleCallback = (data: any) => {
        setFormData((prevState) => ({
            ...prevState,
            ...data,
        }));
        setIsFormSubmitted(false);
    };

    const Submit = () => {
        alert("Form has been submitted successfully")
    }

    useEffect(() => {
        if (formData?.full_name && formData?.full_name?.length > 0 &&
            formData?.email_id && formData?.email_id?.length > 0) {
            setDisabled(false)
        }

    }, [formData])

    return (
        <>
            <div className="banner">
                <img src={BannerContact} alt="" title="" />
            </div>

            <div className="container pt-4 pb-4">
                <div className="row justify-content-center">
                    <div className="col-md-6 col-sm-12">
                        <div className="section contactUsDetails">
                            <h1>Get In Touch</h1>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>

                            <ContactForm col='2'></ContactForm>
                        </div>

                    </div>
                    <div className="col-md-6 col-sm-6">
                        Contact Map
                    </div>
                </div>
            </div>
        </>
    );
};
export default ContactUs;
