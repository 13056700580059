import Banner from "../../images/blog.jpg"
import InsightsItem from "./InsightsItem";

const Insights = () => {

    return (
        <>
            <div className="banner">
                <img src={Banner} alt="" title="" />
            </div>
            <InsightsItem></InsightsItem>
        </>
    );
};
export default Insights;
