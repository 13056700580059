import React, { createContext, useCallback, useEffect, useState } from "react";
import { GetPracticeAreas, getTeam, getBlog } from "../../servies/services";
import { objectToQueryString } from "../../Library/Utility/Utility";

type defaultType = { id: string; name: string };

type ThemeContextType = {
    orgUser: defaultType;
    updateOrgUser: (data: defaultType) => void;
    updateBgColor: (data: string) => void;
    bgTextColor: string;
    breadcrumb: React.ReactNode;
    practiceAreasMain: any;
    teamData: any;
    updateteamData: (data: any) => void;
    blogList: any;
};

export const ThemeContext = createContext<ThemeContextType>({
    orgUser: { id: "", name: "" },
    updateOrgUser: (data: defaultType) => null,
    updateBgColor: (data: string) => null,
    bgTextColor: "",
    breadcrumb: "",
    practiceAreasMain: [],
    teamData: [],
    updateteamData: (data: any) => null,
    blogList: []
});

const { Provider } = ThemeContext;

export const ThemeConsumer = ThemeContext.Consumer;

const defaultValue: defaultType = { id: "", name: "" };

type ThemeProviderProps = {
    children: any;
};

const ThemeProvider = ({ children }: ThemeProviderProps) => {
    const [orgUser, setOrgUser] = useState(defaultValue);
    const [bgTextColor, setBgTextColor] = useState("");
    const [breadcrumb, setBreadcrumb] = useState<any>("");
    const [teamData, setTeamData] = useState<any>([]);

    const [isLoadingPractice, setIsLoadingPractice] = useState(false)
    const [isLoadingTeam, setIsLoadingTeam] = useState(false)
    const [isLoadingBlog, setIsLoadingBlog] = useState(false)

    const updateteamData = useCallback((data: any) => {
        setTeamData(data);
    }, []);

    const updateOrgUser = useCallback((data: any) => {
        setOrgUser(data);
    }, []);

    const updateBgColor = useCallback((data: any) => {
        setBgTextColor(data);
    }, []);

    const [practiceAreasMain, setPracticeAreasMain] = useState([]);
    useEffect(() => {
        const getData = async () => {
            setIsLoadingPractice(false)
            const query = {
                status: "1"
            }
            const APIResponse = await GetPracticeAreas(objectToQueryString(query));
            setIsLoadingPractice(true)
            if (APIResponse?.data?.isSuccess === true) {
                let updatedList: any = []
                APIResponse?.data?.data?.map((item: any) => {
                    return updatedList.push({ text: item?.title, id: item?._id, value: item?.slug })
                })
                setPracticeAreasMain(APIResponse?.data?.data?.reverse());
            } else {
                console.error("something went wrong, please try after sometime.")
            }
        };
        getData();
    }, [])

    useEffect(() => {
        const getData = async () => {
            setIsLoadingTeam(false)
            const query = {
                status: "1"
            }
            const APIResponse = await getTeam(objectToQueryString(query));
            setIsLoadingTeam(true)
            if (APIResponse?.data?.isSuccess === true) {
                if (APIResponse?.data?.data) {
                    updateteamData(APIResponse?.data?.data?.reverse())

                }
            } else {
                console.error("something went wrong, please try after sometime.")
            }

        };
        getData();
    }, [updateteamData])

    console.log("customPostType", setPracticeAreasMain)


    const [blogList, setBlogList] = useState([]);
    useEffect(() => {
        setIsLoadingBlog(false)
        const getData = async () => {
            const query = {
                status: '1'
            }
            const APIResponse = await getBlog(objectToQueryString(query));
            setIsLoadingBlog(true)
            if (APIResponse?.data?.isSuccess === true) {
                setBlogList(APIResponse?.data?.data);
            } else {
                console.error("something went wrong, please try after sometime.")
            }
        };
        getData();
    }, [])

    return (
        <>
            {isLoadingPractice && isLoadingTeam && isLoadingBlog &&
                <Provider
                    value={{
                        orgUser,
                        updateOrgUser,
                        bgTextColor,
                        updateBgColor,
                        breadcrumb,
                        practiceAreasMain,
                        teamData,
                        updateteamData,
                        blogList
                    }}
                >
                    {children}
                </Provider>
            }
        </>
    );
};

export { ThemeProvider };
