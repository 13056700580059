import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';
import './css/bootstrap.css';
import './css/style.css';
import 'react-toastify/dist/ReactToastify.css';
import 'font-awesome/css/font-awesome.css';
import { ToastContainer } from 'react-toastify';
import { setUpAxios } from './servies/services';
import HomePage from './container/Home/Home';
import { ThemeProvider } from './Context/Theme/Context';
import PageNotFound from './container/PageNotFound';
import Wrapper from './container/Wrapper';
// import Category from './container/Category';
import ScrollToTop from './components/ScrollToTop';
// import ProductDetails from './container/ProductDetailsNew';
import ContactUs from './container/ContactUs';
import Insights from './container/Insights/Insights';
import InsightDetails from './container/Insights/InsightsDetails';
import Capabilities from './container/Capabilities/Capabilities';
import People from './container/People/People';
import AboutUs from './container/AboutUs/AboutUs';
import Pages from './container/Pages/Pages';
// import AboutUs from './container/AboutUs';
// import MainCategoryComp from './container/MainCategory';
// import SubCategoryComp from './container/SubCategory';
// import blogList from './container/blogList';
// import CMSTemplate from './container/CMS/CMS';
// import HomeopathicProductCatalog from './container/HomeopathicProductCatalog';
// import CatalogusDownload from './container/CatalogusDownload'

function App() {
  setUpAxios();
  console.log("window.location.pathname setUpAxios", window.location.pathname)
  console.log("window.location.search setUpAxios", window.location.search)
  return (
    <>
      {/* basename={'/tlegalintranet-admin'} */}
      <BrowserRouter >
        <ThemeProvider>
          <ScrollToTop path={window.location.pathname} />
          <Wrapper>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/insights" element={<Insights />} />
              <Route path="/insights/:slug" element={<InsightDetails />} />
              <Route path="/capabilities" element={<Capabilities />} />
              <Route path="/capabilities/:slug" element={<Capabilities />} />
              {/* <Route path="/people" element={<People />} /> */}
              <Route path="/people/:slug" element={<People />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/cms/:slug" element={<Pages />} />
              
              {/* <Route path="/category" element={<MainCategoryComp />} />
              <Route path="/category/:category" element={<SubCategoryComp />} />
              <Route path="/category/:category/:subCategory" element={<blogList />} />
              <Route path="/category/:category" element={<Category />} />
              <Route path="/category/:category/:subCategory/:product_title" element={<ProductDetails />} />
              <Route path="/homeopathic-product-catalog" element={<HomeopathicProductCatalog />} />
              
              <Route path="/download-catalogue" element={<CatalogusDownload />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/distributors" element={<AboutUs />} />
              <Route path="/cms/:title" element={<CMSTemplate />} /> */}

              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </Wrapper>
          <ToastContainer></ToastContainer>
        </ThemeProvider>
      </BrowserRouter>
    </>
  );
}

export default App