import { useParams } from "react-router-dom";
import Banner from "../../images/about-us.jpg"
import { useEffect, useState } from "react";
import { CMSDetails } from "../../servies/services";

const Pages = () => {

    const { slug } = useParams()
    console.log("title", slug)

    const [cmsDetailsAPI, setCmsDetailsAPI] = useState<any>([]);
    useEffect(() => {
        const getData = async () => {
            const APIResponse = await CMSDetails(slug);
            if (APIResponse?.data?.isSuccess === true) {
                setCmsDetailsAPI(APIResponse?.data?.data);
            } else {
                console.error("something went wrong, please try after sometime.")
            }
        };
        getData();
    }, [slug])

    return (
        <>
            <div className="banner">
                <img src={Banner} alt="" title="" />
            </div>

            <div className="capabilities-details">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-12 col-12">
                            <h1>{cmsDetailsAPI?.title}</h1>
                            <div dangerouslySetInnerHTML={{ __html: cmsDetailsAPI?.description }} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="divider"></div>
            <div className="what-sets-apart">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <h1>What Sets Us Apart</h1>
                        </div>

                        <div className="col-md-4 col-12">
                            <div className="inner">
                                <h2>Continuing Professionalism</h2>
                                <p>Our team of seasoned counsels believe in upholding the highest standards of integrity, confidentiality, and excellence.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-12">
                            <div className="inner">
                                <h2>Expertise-Driven Precision</h2>
                                <p>Utilising our comprehensive expertise, we delve deep to understand our client’s requirements, and offer precise solutions tailored to their unique needs.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-12">
                            <div className="inner">
                                <h2>Accessible Expertise</h2>
                                <p>We believe in balancing quality services with transparency and affordability. If you wish to know more about us, please write to us at info@initiumls.com</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};
export default Pages;
