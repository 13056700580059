import { NavLink, useNavigate, useParams } from "react-router-dom";
import BlogImage from "../../images/blog-details.jpg"
import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../Context/Theme/Context";
import DefaultImage from "../../images/default.jpg"
import defaultBanner from "../../images/defaultBanner.jpg"
import moment from "moment";
const InsightDetails = () => {
    const navigate = useNavigate()
    const { blogList } = useContext(ThemeContext)
    const [item, setItem] = useState<any>({})
    const { slug } = useParams()

    useEffect(() => {
        const data = blogList?.find((item: any) => item.slug === slug)
        setItem(data)
    }, [slug])

    return (
        <>
            <div className="banner">
                <img src={item?.image ? item?.image : defaultBanner} alt="" title="" />
            </div>
            <div className="blog-details">
                <div className="container ">
                    <div className="row">
                        <div className="col-md-5 col-5 blog-details-list">
                            <h2>Follow Us On</h2>

                            <h1>Recent Post</h1>
                            {blogList?.slice(0, 5)?.map((item1: any) => {
                                return (
                                    <div className="row mb-3" onClick={() => navigate(`/insights/${item1?.slug}`)}>
                                        <div className="col-md-6 col-6">
                                            <div className="imagePlaeholder">
                                                <img src={item1?.image ? item1?.image : DefaultImage} alt="" title="" />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-6">
                                            <div className="inner">
                                                <h3>{item1?.title}</h3>
                                                <div className="tag">
                                                    <div className="dateTag">{moment(item1?.publish_date).format("DD/MM/YYYY")}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>

                        <div className="col-md-7 col-7 mb-4">
                            <h3>{item?.title}</h3>
                            <div className="tag">
                                <div className="dateTag">{moment(item?.publish_date).format("DD/MM/YYYY")}</div>
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: item?.description }} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default InsightDetails;
