import { NavLink, useLocation } from 'react-router-dom';
import Logo from '../images/logo.png'
import { useContext, useEffect, useRef, useState } from 'react';
import { ThemeContext } from '../Context/Theme/Context';
import NavTab from './NavTab';
import { Button } from '../Library/Module';

const Header = () => {
  const { practiceAreasMain, teamData } = useContext(ThemeContext)
  console.log("practiceAreasMain", practiceAreasMain)
  console.log("teamData", teamData)
  const [list, setList] = useState([])

  useEffect(() => {
    if (practiceAreasMain) {
      const newList: any = []
      practiceAreasMain?.map((i: any) => {
        i.subMenu = teamData?.filter((ab: any) => {
          return ab?.category === i?.slug
        })


        return newList.push(i)
      })
      console.log("newList", newList)
      setList(newList)
    }
  }, [practiceAreasMain, teamData])

  const [hamburgerMenu, setHamburgerMenu] = useState(false)
  const [mobileOnly, setMobileOnly] = useState(false)

  useEffect(() => {
    window.addEventListener("resize", resize);
    const windwoWidth = window.innerWidth <= 990;
    console.log("windwoWidth", window.innerWidth)
    setHamburgerMenu(windwoWidth)
    setMobileOnly(windwoWidth)
    resize();
  }, [])

  const handleOpen = () => {
    setHamburgerMenu(true)
  }

  const handleClose = () => {
    setHamburgerMenu(false)
  }

  const resize = () => {
    const windwoWidth = window.innerWidth <= 990;
    setHamburgerMenu(windwoWidth)
    setMobileOnly(windwoWidth)
  }

  
  const newPath: any = useLocation();
  const prevCountRef: any = useRef(null);

  useEffect(() => {
    if (prevCountRef.current !== undefined && prevCountRef.current !== newPath) {
      if (newPath?.pathname === prevCountRef.current?.pathname) {
        console.log("newPath", newPath?.pathname, prevCountRef.current?.pathname)
        setHamburgerMenu(false)
      } else{
        setHamburgerMenu(true)
      }
    }
    prevCountRef.current = newPath;
  }, [newPath]); // runs every time `count` changes


  return (
    <>
      <div className="headerTop">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12 col-12">
              <ul>
                <li>MON -FRI  |  09.00 - 16.00</li>
                <li>+123 456 789</li>
                <li>contact@initiumlegal.com</li>
              </ul>
            </div>
            <div className="col-md-6 col-sm-12 col-12"></div>

          </div>
        </div>
      </div>
      <div className="header">
        <div className="container">
          <div className={`${mobileOnly ? "row" : "row justify-content-center align-items-center"}`}>
            <div className="col-md-5 col-sm-6 col-6">
              <div className='logoWraper'>
                <NavLink to={'/'}><img src={Logo} title="" alt='' /></NavLink>
              </div>
            </div>
            <div className="col-md-7 col-sm-6 col-6 text-right">
              <div className="main_nav">

                {mobileOnly ?
                  <div className={hamburgerMenu ? `` : "main_nav main_nav_mobile"}>
                    {!hamburgerMenu ?
                      <>
                        <div className='innerMenu'>
                          <div className="hangerMenuClose">
                            <Button onClick={() => handleOpen()}
                              buttonStyleOutline
                              buttonStyleType="primary">
                              <span className='lineBox'>
                                <span className='line'></span>
                                <span className='line'></span>
                                <span className='line'></span>
                              </span>
                              <span>Close</span>
                            </Button>
                          </div>
                          <ul>
                            <li><NavLink to={'/'}>Home</NavLink></li>
                          </ul>
                          <NavTab></NavTab>
                        </div>
                        <div className='overlay'></div>
                      </>
                      :
                      <div className="hangerMenuOpen">
                        <Button
                          buttonStyleOutline
                          buttonStyleType="primary"
                          onClick={() => handleClose()} >
                          <span className='lineBox'>
                            <span className='line'></span>
                            <span className='line'></span>
                            <span className='line'></span>
                          </span>
                          <span>Menu</span>
                        </Button>
                      </div>
                    }
                  </div>
                  :
                  <NavTab></NavTab>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Header;
