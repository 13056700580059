import { NavLink, useLocation } from 'react-router-dom'
import { Button } from '../Library/Module'
import { useEffect, useState } from 'react'
import LogoFooter from "../images/logoFooter.png"
import NavTab from './NavTab'
import { trySessionStorage } from '../Library/Utility/Utility'
const Footer = () => {

    const [open, setOpen] = useState(false)

    const CloseDrawer = () => {
        sessionStorage.setItem("disclaimer", "false")
        setOpen(false)
    };

    useEffect(() => {
        const disclaimer = trySessionStorage("disclaimer")
        const disclaimerPopup = disclaimer === null ? true : disclaimer
        setOpen(disclaimerPopup)
    }, [])

    const Redirect = () => {
        window.location.assign("https://www.google.com/")
    }

    return (
        <>
            {open &&
                <>
                    <div className="popup">
                        <div className='popup-content'>
                            <h2>Disclaimer </h2>
                            <p>The Bar Council of India prohibits advocates from advertising or soliciting work. By accessing this website, you confirm that you are seeking information about Initium Legal Services and its counsels on your own accord and not due to any solicitation, advertisement, or inducement from us.</p>
                            <p>This website aims to provide general information about Initium Legal Services and its counsels, and is not intended to solicit or advertise work. The content displayed on this website is for informational purposes only and should not be considered legal advice. Please review our terms of use and privacy policy before you access this website.</p>
                            <div className='custom-flex'>
                                <Button className='btn btn-outline-primary' onClick={() => CloseDrawer()}>I Agree, Proceed to Website</Button>
                                <Button className='btn btn-outline-primary red' onClick={() => Redirect()}>Close</Button>
                            </div>
                        </div>
                    </div>
                    <div className='popup-overlay'></div>
                </>
            }

            <div className='footerTop'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-12 col-12">
                            <ul>
                                <li>MON -FRI  |  09.00 - 16.00</li>
                                <li>+123 456 789</li>
                                <li>contact@initiumlegal.com</li>
                            </ul>
                        </div>
                        <div className="col-md-6 col-sm-12 col-12 text-right">
                            <ul>
                                <li>1101, Vainganga, Sector 5, Greater Kailash, Dehli </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
            <div className='footerSection'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-5 col-12 '>
                            <div className='footerLogoInner'>
                                <img src={LogoFooter} alt='' title='' />
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.  </p>
                            </div>
                        </div>
                        <div className='col-md-2 col-12 '><NavTab></NavTab></div>
                        <div className='col-md-2 col-12 '>
                            <ul>
                                <li> <NavLink to={'/cms/terms-of-use'}>Terms of USe</NavLink> </li>
                                <li> <NavLink to={'/cms/privacy-policy'}>Privacy Policy</NavLink> </li>
                            </ul>
                        </div>
                        <div className='col-md-3'>
                            <h3>Subscribe</h3>
                        </div>
                    </div>
                </div>
            </div>

            <div className='footerTop'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-12 text-center">
                            <ul>
                                <li> Copyright 2025 All Right Reserved by Initium Legal Services </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}
export default Footer