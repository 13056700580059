import { NavLink } from "react-router-dom";
import Banner from "../../images/people.jpg"
import TeamPhoto from "../../images/team1.jpg"
import { useContext, useRef } from "react";
import { ThemeContext } from "../../Context/Theme/Context";
const People = () => {
    const { teamData } = useContext(ThemeContext)
    const myRef: any = useRef(null);
    const executeScroll = () => setTimeout(() => {
        myRef.current.scrollIntoView({ behavior: "smooth", });
    }, 100);
    return (
        <>
            <div className="banner">
                <img src={Banner} alt="" title="" />
            </div>

            <div className="capabilities-details">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-6 col-6">
                            <h1>Expert Insights, Precise Results</h1>
                            <p>At Initium Legal Services, our professionals are recognized for their exceptional expertise, strategic insight, and commitment to excellence.</p>
                            <div className="quote">Specializing across a broad range of legal disciplines, our professionals deliver a depth of knowledge and expert ability to every matter, ensuring effective solutions tailored to our client's needs. With extensive experience and a history of working alongside industry leaders, our professionals are uniquely positioned to navigate even the most complex legal landscapes.</div>
                            <p>Beyond resolving legal challenges, our team strives to build lasting relationships founded on trust and collaboration. It's this personalized approach that transforms us from legal counsel into trusted advisors.</p>
                        </div>
                        <div className="col-md-6 col-6">
                            <img src={Banner} alt="" title="" />
                        </div>
                    </div>
                </div>
            </div>
            <div ref={myRef}>
                <div className="capabilities-list">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-12 text-center logoTeam">
                                <h1>Meet our Core Team</h1>
                                <ul>
                                    {teamData?.map((item: any) => {
                                        return <li><NavLink onClick={() => executeScroll()} to={`/people/${item?.slug}`}>{item?.title}</NavLink></li>
                                    })}
                                </ul>
                            </div>
                            <div className="divider"></div>
                        </div>
                    </div>
                </div>
                <div className="people-details">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-md-4 col-12 ">
                                <div className="teamPhoto selection">
                                    <img src={TeamPhoto} alt="" title="" />
                                </div>

                                <div className="selection">
                                    <h3>Sectors</h3>
                                    <ul>
                                        <li>Pharmaceutical</li>
                                        <li>Healthcare</li>
                                        <li>Manufacturing</li>
                                        <li>Information Technology</li>
                                        <li>E-Commerce</li>
                                    </ul>
                                </div>

                                <div className="selection">
                                    <h3>Professional Experience</h3>
                                    <ul>
                                        <li>KHAITAN & co.</li>
                                        <li>Tatva Legal, Hyderabad</li>
                                    </ul>
                                </div>

                            </div>
                            <div className="col-md-7 col-12 people-details-content">
                                <div className="infobox">
                                    <h1>Ifrazunnisa Khan</h1>
                                    <p>Counsel</p>
                                </div>
                                <div className="infobox2">
                                    <div className="education">Masters in Corporate Law (MCL)<br></br>
                                        University of Cambridge</div>
                                    <div className="education">BA-LLB<br></br>
                                        Osmania University</div>

                                </div>
                                <p>Ifraz is an accomplished corporate counsel with nearly a decade of experience at leading law firms in India. She specializes in transactions, corporate advisory, and commercial legal services. Based in Hyderabad, India, she is strategically positioned to provide expert legal counsel for businesses ranging from startups to established enterprises across southern India.</p>
                                <p>Her extensive expertise spans various sectors, including healthcare, pharmaceutical, and information technology. She has significant experience collaborating with industry leaders across various business sectors and transactions. In recognition of her outstanding professional achievements, Ifraz was awarded with the prestigious “Best Young Achiever - Law Firm” award by India Legal Awards in 2022.</p>
                                <h4>Capabilities</h4>
                                <ul>
                                    <li>Corporate acquisitions, strategic alliances and joint ventures, private equity and venture capital transactions</li>
                                    <li>Corporate general advisory and commercial / business contracts</li>
                                    <li>Employment documentation and compliances</li>
                                    <li>Real estate documentation</li>
                                    <li>Compliance and data privacy matters</li>
                                </ul>

                                <h4>Notable Matters</h4>
                                <ul>
                                    <li>Advised a portfolio company of a global private equity fund in acquisition of target companies engaged in construction of life-sciences projects in Hyderabad, with deal size of approximately INR 200 Crores.</li>
                                    <li>Represented a leading listed pharmaceutical company in its corporate acquisition, valued at INR 155 Crores.</li>
                                    <li>Acted for a global life sciences investor in late stage venture capital investment in a leading health-tech startup, contributing to a funding round of INR 544 Crores.</li>
                                    <li>Assisted the promoters of a leading pharmaceutical company in their sale stake to a global private equity fund.</li>
                                    <li>Advised a global pharmaceutical company in setting up an offshore joint venture for clinical research in United States.</li>
                                    <li>Acted for a leading hospital chain in structuring and setting up of employee incentive schemes.</li>
                                    <li>Acting for a global manufacturing company in leasing of its commercial premises in Hyderabad.</li>
                                    <li>Representing key industrial organisations in structuring and review of business and commercial contracts and data privacy compliances.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};
export default People;
