import Banner from "../../images/about-us.jpg"
import AboutUs01 from "../../images/aboutUs01.jpg"
import AboutUs02 from "../../images/aboutUs02.jpg"
import AboutUs03 from "../../images/AboutUs03.jpg"


const AboutUs = () => {
    return (
        <>
            <div className="banner">
                <img src={Banner} alt="" title="" />
            </div>

            <div className="capabilities-details">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-6 col-12">
                            <h1>Legal Advice, Simplified</h1>
                            <p>Initium Legal Services is a contemporary network of legal counsels with one core objective: to provide reliable, high-quality legal services - delivered in a way that’s clear, approachable, and straightforward.</p>
                        </div>
                        <div className="col-md-6 col-12">
                            <img src={AboutUs01} alt="" title="" />
                        </div>
                    </div>
                </div>
            </div>


            <div className="our-philosophy">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-12">
                            <h1>Our Philosophy</h1>
                            <h3>Initium means “to begin”</h3>
                            <p>At Initium Legal Services, we are beginning to reimagine the role of contemporary legal service providers.</p>
                            <p>Great legal advice is not often about jargon - it is grounded in trust, precision, value and ease. We believe that astute legal services should combine timeless principles of professionalism with modern accessibility. Legal advice should be professional yet accessible, straightforward and tailored to the individual needs of each client.</p>
                            <p>With Initium, our foundation is built on integrity, clarity, and a commitment to simplify the complex. Our aim is to become our clients’ trusted advisors and deliver reliable and effective solutions for individuals and businesses.</p>
                        </div>
                        <div className="col-md-6 col-12">
                            <img src={AboutUs02} alt="" title="" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="divider"></div>
            <div className="our-philosophy noimage">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-12">
                            <h1>Our Values</h1>
                            <p className="mb-5">We strive to uphold the following values in our professional way of life:</p>
                            <img src={AboutUs03} alt="" title="" />
                        </div>
                        <div className="col-md-6 col-12">
                            <div className="list">
                                <h2>Integrity</h2>
                                <p>We value that our clients trust us to act in their best interests. We are committed to upholding the highest standards of honesty and ethics at all times.</p>
                            </div>

                            <div className="list">
                                <h2>Simplicity</h2>
                                <p>Legal matters can be complicated. We aim to demystify the legal process and present information in a clear, concise manner.</p>
                            </div>

                            <div className="list">
                                <h2>Reliability</h2>
                                <p>We endeavour to provide consistent, dependable advice and quality representation, offering support and expertise which can be relied upon.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="what-sets-apart">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <h1>What Sets Us Apart</h1>
                        </div>

                        <div className="col-md-4 col-12">
                            <div className="inner">
                                <h2>Continuing Professionalism</h2>
                                <p>Our team of seasoned counsels believe in upholding the highest standards of integrity, confidentiality, and excellence.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-12">
                            <div className="inner">
                                <h2>Expertise-Driven Precision</h2>
                                <p>Utilising our comprehensive expertise, we delve deep to understand our client’s requirements, and offer precise solutions tailored to their unique needs.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-12">
                            <div className="inner">
                                <h2>Accessible Expertise</h2>
                                <p>We believe in balancing quality services with transparency and affordability. If you wish to know more about us, please write to us at info@initiumls.com</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};
export default AboutUs;
