import { NavLink } from "react-router-dom";
import InsightsItem from "../Insights/InsightsItem";

const HomeBlog = () => {
    return (
        <>
            <div className="divider"></div>
            <div className="homeBlog">
                <div className="container ">
                    <div className="row justify-content-center">
                        <div className="col-md-5 col-12">
                            <div className="text-center">
                                <h1 className="text-center">Keeping You Informed</h1>
                                <p className="text-center">Stay updated with legal developments, industry insights, and expert perspectives from our counsels.</p>
                            </div>
                        </div>
                    </div>
                    <InsightsItem slice={3}></InsightsItem>
                    <div className="text-center">
                        <NavLink to="/insights">Get more Insights</NavLink>
                    </div>
                </div>
            </div>
        </>
    );
};
export default HomeBlog;
