import { NavLink, useLocation, useParams } from "react-router-dom";
import Banner from "../../images/practice.jpg"
import { useContext, useEffect, useRef, useState } from "react";
import { ThemeContext } from "../../Context/Theme/Context";
import DefaultImage from "../../images/capabilities.jpg"
import PracticeMain from "../../images/practice_main.jpg"
import useMobile from "../../Library/Utility/useMobile";
import { Button, SideDrawer } from "../../Library/Module";
const Capabilities = () => {
    const { practiceAreasMain } = useContext(ThemeContext)
    const { mobileOnly } = useMobile()
    const [data, setData] = useState<any>({})
    const { slug } = useParams()
    const myRef: any = useRef(null);
    const executeScroll = () => setTimeout(() => {
        myRef.current.scrollIntoView({ behavior: "smooth", });
    }, 100);

    useEffect(() => {
        if (slug) {
            const dd = practiceAreasMain?.find((item: any) => { return item?.slug === slug })
            setData(dd)
        } else {
            setData(practiceAreasMain?.[0])
        }
    }, [slug])

    const [open, setOpen] = useState(false)
    const handleSidedrawer = () => {
        setOpen(true)
    }

    const CloseDrawer = () => {
        setOpen(false)
    };

    const newPath: any = useLocation();
    const prevCountRef: any = useRef(null);

    useEffect(() => {
        if (prevCountRef.current !== undefined && prevCountRef.current !== newPath) {
            if (newPath?.pathname === prevCountRef.current?.pathname) {
                console.log("newPath", newPath?.pathname, prevCountRef.current?.pathname)
                setOpen(true)
            } else {
                setOpen(false)
            }
        }
        prevCountRef.current = newPath;
    }, [newPath]); // runs every time `count` changes

    return (
        <>
            <div className="banner">
                <img src={Banner} alt="" title="" />
            </div>

            <div className="capabilities-details">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-6 col-12">
                            <h1>Precision in advice, Clarity in Action</h1>
                            <p>Our counsels have significant experience of working with industry leaders, across sectors and practice areas.
                                Our key capabilities and expertise span various specialized practice areas, to support organisations and individuals in various stages of operations and transactions.</p>
                        </div>
                        <div className="col-md-6 col-12">
                            <img src={PracticeMain} alt="" title="" />
                        </div>
                    </div>
                </div>
            </div>
            <div ref={myRef} id="content">
                <div className="capabilities-list">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-12">
                                <h1>Practice Areas</h1>
                                {mobileOnly &&
                                    <>
                                        <Button
                                            className="btn btn-outline-primary"
                                            buttonStyleOutline
                                            buttonStyleType="primary"
                                            onClick={() => handleSidedrawer()} >
                                            <span className='lineBox'>
                                                <span className='line'></span>
                                                <span className='line'></span>
                                                <span className='line'></span>
                                            </span>
                                            <span>Practice Areas Menu</span>
                                        </Button>
                                        {open &&
                                            <SideDrawer
                                                size={'450px'}
                                                pagetitle={`Practice Areas`}
                                                action={CloseDrawer}>
                                                <ul className="practice-areas-menu">
                                                    {practiceAreasMain?.map((item: any) => {
                                                        return <li><NavLink onClick={() => executeScroll()} to={`/capabilities/${item?.slug}`}>{item?.title}</NavLink></li>
                                                    })}
                                                </ul>
                                            </SideDrawer>
                                        }
                                    </>}
                                {!mobileOnly && <ul>
                                    {practiceAreasMain?.map((item: any) => {
                                        return <li><NavLink onClick={() => executeScroll()} to={`/capabilities/${item?.slug}`}>{item?.title}</NavLink></li>
                                    })}
                                </ul>
                                }
                            </div>
                            <div className="divider"></div>
                        </div>
                    </div>
                </div>


                <div className="capabilities-details">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-12">
                                <h1>{data?.title}</h1>
                                <div dangerouslySetInnerHTML={{ __html: data?.description }} />
                            </div>
                            <div className="col-md-4 col-12">
                                <div className="imagePlaceholder2">
                                    <img src={data?.image?.length > 5 ? data?.image : DefaultImage} alt="" title="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};
export default Capabilities;
