import { NavLink } from "react-router-dom"

const NavTab = () => {
    return (

        <ul>
            <li> <NavLink to={'/about-us'}>About Us</NavLink> </li>
            <li> <NavLink to={'/capabilities'}>Capabilities</NavLink> </li>
            {/* <li> <NavLink to={'/people'}>People</NavLink> </li> */}
            <li> <NavLink to={'/insights'}>Insights</NavLink> </li>
            <li> <NavLink to={'/contact-us'}>Contact Us</NavLink> </li>
        </ul>

    )
}

export default NavTab

