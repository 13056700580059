import { NavLink } from "react-router-dom";
import InsightsItem from "../Insights/InsightsItem";
import PracticeImage from "../../images/practice_home.jpg"
import { useContext, useState } from "react";
import { ThemeContext } from "../../Context/Theme/Context";
const HomePractice = () => {
    const { practiceAreasMain } = useContext(ThemeContext)
    const [show, setShow] = useState('')
    const showOnClick = (id: any) => {
        setShow(id)
    }
    return (
        <>
            <div className="divider"></div>
            <div className="homePractice">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-md-4 col-12">
                            <h1>Practice Areas</h1>
                            <p>Our team of seasoned attorneys is committed to delivering tailored solutions, ensuring you receive the attention you deserve.</p>
                            <p><NavLink to="/insights">Get more Insights</NavLink></p>
                            <div className="">
                                <img src={PracticeImage} alt="" title=""></img>
                            </div>
                        </div>
                        <div className="col-md-7 col-12">
                            <div className="accordion">
                                {practiceAreasMain?.map((item: any) => {
                                    return (
                                        <div className="accordionItem">
                                            <div className="accordionItemText">
                                                <div className="accordionHeader" onClick={() => showOnClick(item?.slug)}>
                                                    <h2>{item?.title}</h2>
                                                </div>
                                                {show === item?.slug &&
                                                    <div className="accordionbody" >
                                                        <div dangerouslySetInnerHTML={{ __html: item?.short_description }} />
                                                        <p className="mt-3"><NavLink to={`/capabilities/${item?.slug}#content`}>Read More</NavLink></p>
                                                    </div>
                                                }
                                            </div>
                                            <div className="accordionItemIcon">
                                                {show === item?.slug ? '-' : "+"}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default HomePractice;
