import HomeBlog from "./HomeBlog";
import HomePractice from "./HomePractice";
import Banner from "../../images/home.jpg"
import { NavLink } from "react-router-dom";

const HomePage = () => {
    return (
        <>
            <div className="banner">
                <img src={Banner} alt="" title="" />
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="homeContentWrapper">
                            <div className="homeContent">
                                <h3>At Initium Legal Services, we believe that great legal advice is more than just words—it's about
                                    <span> trust, precision, and value.</span></h3>
                                <p><NavLink to="/about-us">Learn about us</NavLink></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <HomePractice></HomePractice>
            <HomeBlog></HomeBlog>
        </>
    );
};
export default HomePage;
